import React from 'react'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import ProductGrid from 'components/product-grid'
import Spacer from 'components/spacer'
import useCompactTractors from 'data/use-compact-tractors'
import { Link } from 'gatsby'
import Button from 'components/button'

const CompactTractorsPage = () => {
  const compactTractorsData = useCompactTractors()
  return (
    <Layout
      seo={{
        title: 'Compact Tractors',
        description:
          'Build and price a John Deere compact tractor to meet your needs. Customize your build with options, implements, attachments, warranty, and financing and easily calculate the costs.',
      }}
    >
      <ScrollableContent>
        <h2>Compact Tractors</h2>
        <Spacer size='m' />
        {compactTractorsData.length > 0 ? (
          <ProductGrid items={compactTractorsData} />
        ) : (
          <React.Fragment>
            <p>No compact tractors are available to build and price at this time.</p>
            <Button
              as={Link}
              to='/'
              ghost
              style={{ display: 'inline-block', textDecoration: 'none' }}
            >
              Go to homepage
            </Button>
          </React.Fragment>
        )}
      </ScrollableContent>
    </Layout>
  )
}

export default CompactTractorsPage
